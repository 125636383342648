<template>
  <div class="playlist-slide">
    <div class="playlist-header">
      <div class="meta">
        <h1>{{ section.title }}</h1>
        <button @click="$emit('hide')">Close</button>
      </div>
    </div>

    <swiper-container
      :slidesPerView="1"
      :pagination="{
        clickable: true,
      }"
      :navigation="true"
      class="playlist-swiper"
      >
      <swiper-slide v-for="(slide, idx) in section.items" :key="idx" class="slide">
        <div v-html="slide.content"></div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';

  export default {
    props: {
      section: {
        type: Object,
        default: null
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .playlist-slide {
    position: relative;
  }

  .playlist-swiper {
    overflow-y: scroll;
    height: 100vh;
  }

  .playlist-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: $neutral-100;
    color: $neutral-500;
    border-bottom: 1px solid $neutral-300;
    padding: 0 1.5rem;

    .meta {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  .slide {
    padding: 3.5rem 1.5rem;
    overflow-y: scroll;
    background: $neutral-100;
    color: $neutral-500;
  }
</style>