<template>
	<header :class="{ 'has-app' : hasMini, 'showing-support' : showingSupport }">
		<div class="flex mobile nav-wrapper">
			<template>
				<ul>
					<li class="sign-up" v-if="!showMenu">
						<span class="text is-button no-arrow" v-if="!loggedIn" v-on:click="toggleSignUpForm">Sign Up</span>
						<router-link to="/" v-else>
							<global-svg type="home" color="#ffffff" style="width:16px;"></global-svg>
						</router-link>
					</li>
				</ul>

				<h1>
					<a href="/" v-on:click.stop.prevent="homeClick">
						<div class="brand">
							<global-svg type="logo" width="62px" height="18px" color="#fff"></global-svg>
							<span v-if="isPro" class="beta-tag pro-tag">Pro</span>
							<span v-if="determineTag() == 'beta'" class="beta-tag">Beta</span>
							<span v-if="determineTag() == 'alpha'" class="beta-tag">Alpha</span>
						</div>
					</a>
				</h1>

				<span class="hamburger-activate-wrapper">
					<ChallengesOverview/>

					<button v-on:click="toggleMenu" class="hamburger-activate">
						<div class="hamburger-inner">
							<span class="intercom-notification" v-if="intercomMessages > 0 && !showMenu"></span>
							<global-svg type="hamburger" width="13px" height="13px" color="#FFF" v-if="!showMenu"></global-svg>
							<global-svg type="close" width="11px" height="11px" v-else></global-svg>
						</div>
					</button>
				</span>
			</template>

			<div class="menu-wrapper" :class="{ 'has-app' : hasMini }" v-if="showMenu">
				<div>
					<span class="is-button is-block" v-if="!loggedIn" v-on:click="toggleSignUpForm">Sign Up</span>
					<router-link to="/account" class="account-details" v-else>
						<ProfileImagePartial :profilePicture="account.details.profilePicture" :messages="intercomMessages"></ProfileImagePartial>
						<div class="account-personal">
							<h2>{{ account.details.firstName }} {{ account.details.lastName }} {{ isProHeart }}</h2>
							<span>{{ account.details.email }}</span>
						</div>
					</router-link>
					<ul class="main-nav is-first">


						<!-- <li v-if="isBeta">
							<router-link to="/v5" title="v5" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="headlines"></global-svg>
									</span>
									Recommended <span class="beta-tag">New</span>
								</span>
							</router-link>
						</li> -->


						<!-- <li v-if="isAlpha">
							<router-link to="/demo/v4" title="v4" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="headlines"></global-svg>
									</span>
									v4 Grid
								</span>
							</router-link>
						</li> -->
						<!-- <li v-if="isAlpha">
							<router-link to="/demo/v4-components" title="v4" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="headlines"></global-svg>
									</span>
									v4 Components
								</span>
							</router-link>
						</li> -->
						<!-- <li v-if="isAlpha">
							<router-link to="/recommended" title="Recommended" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="headlines"></global-svg>
									</span>
									Recommended
								</span>
							</router-link>
						</li> -->
						<li>
							<router-link to="/" title="Headlines" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="headlines"></global-svg>
									</span>
									Today <span class="beta-tag">New</span>
								</span>
							</router-link>
						</li>
						<li v-if="loggedIn">
							<router-link to="/dashboard" title="Dashboard" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="dashboard"></global-svg>
									</span>
									Dashboard
								</span>
							</router-link>
						</li>
						<li>
							<router-link to="/threads" title="Discover OneSub Threads" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="blue-heart" width="14px" height="14px" color="#16191c"></global-svg>
									</span>
									Threads
								</span>
							</router-link>
						</li>
						<li v-if="loggedIn == 'DISABLED'">
							<router-link to="/influencers" title="Become a OneSub Influencer" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="blue-heart" width="14px" height="14px" color="#16191c"></global-svg>
									</span>
									Influencers
								</span>
							</router-link>
						</li>
						<li>
							<router-link to="/mission" title="Our Mission" v-on:click.native="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="about" color="#171200"></global-svg>
									</span>
									Mission
								</span>
							</router-link>
						</li>
						<!-- <li>
							<router-link to="/blog" title="Blog" v-on:click="hideMenu()">
								<span class="flex flex-center">
									<span class="icon">
										<global-svg type="blog"></global-svg>
									</span>
									Blog
								</span>
							</router-link>
						</li> -->
					</ul>
				</div>

				<ul class="main-nav" v-if="loggedIn">
					<li v-if="!isPro">
						<span title="Become a OneSub Pro" v-on:click="becomePro">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="go-pro" width="14px" height="15px"></global-svg>
								</span>
								Become a OneSub <span class="pro-tag">Pro</span>
							</span>
						</span>
					</li>
					<li>
						<span title="My Account" v-if="loggedIn && hasThreads" v-on:click="goThreadShare(); hideMenu();">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="account"></global-svg>
								</span>
								+ New Clipping
							</span>
						</span>
					</li>
					<li>
						<router-link :to="'/~' + account.screen_name" v-if="loggedIn && hasThreads" title="My Account" v-on:click="hideMenu()">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="account"></global-svg>
								</span>
								My Threads
							</span>
						</router-link>
					</li>
					<li>
						<router-link to="/account" title="My Account" v-on:click.native="hideMenu()">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="account"></global-svg>
								</span>
								My Account
							</span>
						</router-link>
					</li>
					<li>
						<span title="Contact Us" v-on:click="getSupport">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="support"></global-svg>
								</span>
								Contact Us
							</span>
							<NewMessagePartial :messages="intercomMessages"></NewMessagePartial>
						</span>
					</li>
					<li v-if="isAlpha">
						<span title="Contact Us" v-on:click="triggerReviewCallback">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="support"></global-svg>
								</span>
								Dev: Trigger Review
							</span>
						</span>
					</li>
					<!-- <li v-if="isAlpha">
            <span v-on:click="didSelectTestMessage()">
              <span class="flex flex-center">
                <span class="icon">
                  <global-svg type="signin"></global-svg>
                </span>
                Toggle Modal (Alpha)
              </span>
            </span>
					</li> -->
					<!-- <li v-if="isAlpha">
            <a :href="`https://${documentURL}/`" title="Reload Site">
              <span class="flex flex-center">
                <span class="icon">
                  <global-svg type="signin"></global-svg>
                </span>
                Reload Site
              </span>
            </a>
					</li> -->
					<!-- <li v-if="isAlpha">
            <router-link to="/v3">
              <span class="flex flex-center">
                <span class="icon">
                  <global-svg type="signin"></global-svg>
                </span>
                Version 3.2
              </span>
            </router-link>
					</li> -->
				</ul>
        
				<ul class="main-nav" v-else>
					<li>
						<span title="Contact Us" v-on:click="getSupport">
							<span class="flex flex-center">
								<span class="icon">
									<global-svg type="support"></global-svg>
								</span>
								Contact Us
							</span>
							<NewMessagePartial :messages="intercomMessages"></NewMessagePartial>
						</span>
					</li>
					<li class="faux-link no-border" v-on:click="toggleLoginForm">
						<span class="flex flex-center">
							<span class="icon">
								<global-svg type="signin"></global-svg>
							</span>
							Sign In
						</span>
					</li>
					<!-- <li class="is-search">
						<input type="search" placeholder="Search for stories, articles and publishers…">
					</li> -->
				</ul>
			</div>
		</div>


		<div class="flex desktop nav-wrapper" v-if="!hasMini">
			<h1>
				<a href="/" v-on:click.stop.prevent="homeClick">
					<div class="brand">
						<global-svg type="logo" width="75px" height="20px" color="#fff"></global-svg>
						<span v-if="isPro" class="beta-tag pro-tag">Pro</span>
						<span v-if="determineTag() == 'beta'" class="beta-tag">Beta</span>
						<span v-if="determineTag() == 'alpha'" class="beta-tag">Alpha</span>
					</div>
				</a>
			</h1>

			<ul class="main-nav flex">


				<!-- <li v-if="isBeta">
					<router-link to="/v5" title="v5" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Recommended <span class="beta-tag">New</span>
						</span>
					</router-link>
				</li> -->


				<!-- <li v-if="isAlpha">
					<router-link to="/demo/v4" title="v4" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							v4 Grid
						</span>
					</router-link>
				</li> -->
				<!-- <li v-if="isAlpha">
					<router-link to="/demo/v4-components" title="v4" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							v4 Components
						</span>
					</router-link>
				</li> -->
				<!-- <li v-if="isAlpha">
					<router-link to="/recommended" title="Recommended" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Recommended
						</span>
					</router-link>
				</li> -->
				<li>
					<router-link to="/" title="Headlines" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Today <span class="beta-tag">New</span>
						</span>
					</router-link>
				</li>
				<li v-if="loggedIn">
					<router-link to="/dashboard" title="Dashboard" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Dashboard
						</span>
					</router-link>
				</li>
				<li>
					<router-link to="/threads" title="Threads" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Threads
						</span>
					</router-link>
				</li>
				<li v-if="loggedIn == 'DISABLED'">
					<router-link to="/influencers" title="Influencers" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Influencers
						</span>
					</router-link>
				</li>
				<li>
					<router-link to="/mission" title="Mission" v-on:click.native="hideMenu()">
						<span class="flex flex-center">
							Mission
						</span>
					</router-link>
				</li>
				<!-- <li>
					<router-link to="/blog" title="Blog">
						<span class="flex flex-center">
							Blog
						</span>
					</router-link>
				</li> -->
			</ul>

			<ul class="last-sign">
				<li class="sign-up">
					<span class="text is-button no-arrow" v-if="!loggedIn" v-on:click="toggleSignUpForm">Sign Up</span>
					<span class="text is-button no-arrow is-light" v-if="!loggedIn" v-on:click="toggleLoginForm">Sign In</span>

					<span class="text is-button no-arrow threads-button" v-if="loggedIn && hasThreads" v-on:click="goThreadShare()">
						<global-svg type="blue-heart" color="#FFFFFF" width="11px" height="11px"></global-svg>
						<span>Clip</span>
					</span>
					
					<router-link :to="'/~' + account.screen_name" class="header-gravatar" v-if="loggedIn && hasThreads">
						<span class="my-account-link">My Threads</span>
					</router-link>
          
          <!-- <a href="javascript:void(0)" @click="getSupport">
            <span class="my-account-link">Help</span>
            <NewMessagePartial :messages="intercomMessages"></NewMessagePartial>
          </a> -->

					<router-link to="/account" class="header-gravatar" v-if="loggedIn">
						<span class="my-account-link">My Account</span>
						<ProfileImagePartial :profilePicture="account.details.profilePicture" :messages="intercomMessages"></ProfileImagePartial>
					</router-link>

          <span class="dial-container" v-if="isBeta">
            <dial size="header"/>
          </span>
				</li>
			</ul>
		</div>

		<transition name="fade" mode="out-in">
			<global-modal :visible="showLoginForm" v-if="showLoginForm" v-on:hideModal="toggleLoginForm">

				<div class="modal-section">
					<h2>👋 Sign In to OneSub</h2>

					<p>Welcome back! Sign in to OneSub and our AI will continue working to help you manage your bias and show you true news.</p>
					<p><span class="sign-up-link" v-on:click="toggleSignUp">Not signed up? Get started with OneSub</span></p>

					<div class="is-message is-error" v-if="showError">Sorry, something went wrong. We're looking into it, please try again later.</div>
					<div class="is-message is-error" v-if="showUserError">Sorry, we can't find an account matching that email and password.</div>

					<form class="account-form">

						<Loading class="loader-wrapper" v-if="showLoader"/>

						<label for="email" class="text-label" :class="{ 'is-active' : focusElement == 'email' || signInDetails.email }">Email</label>
						<input type="email" id="email" v-on:focus="toggleFocus('email')" v-model="signInDetails.email" v-on:blur="toggleFocus(false)">

						<label for="password" class="text-label" :class="{ 'is-active' : focusElement == 'password' || signInDetails.password }">Password</label>
						<input type="password" id="password" v-on:focus="toggleFocus('password')" v-model="signInDetails.password" v-on:blur="toggleFocus(false)">
												
						<p class="with-margin no-grow flex" v-if="!showLoader">
							<button class="is-button" v-on:click.prevent="submitForm">Sign In</button>
							<span class="forgot-password" v-on:click="forgotPassword">Forgot Password?</span>
						</p>

					</form>

				</div>
			</global-modal>
		</transition>
	</header>
</template>

<script>

import API from "app/axios.js";

import NewMessagePartial from '@/partials/NewMessage.vue';
import ProfileImagePartial from '@/partials/ProfileImage.vue';
import ChallengesOverview from 'app/components/_v3.3/challenges/ChallengesOverview.vue';


export default {
	name: 'Header',
	components: {
		NewMessagePartial,
		ProfileImagePartial,
		ChallengesOverview
	},
	data() {
		return {
			showLoginForm: false,
			focusElement: false,
			showError: false,
			showUserError: false,
			showMenu: false,
			showingSupport: false,
			intercomMessages: 0,
			signInDetails: {},
			redirectAfterLogin: false,
			showLoader: false
		}
	},
	mounted() {
		this.EventBus.$on('showLoginModal', (args) => {
      
      // legacy
      if (typeof args != 'object'){
        args = {
          mode: args,
        };
      }


      // force a signout first
      window?.localStorage?.removeItem('token');
      this.$store.commit('unsetAccount');
      this.EventBus.$emit("appCallback", {
        type: 'deauthCallback',
        data: 'logout'
      });


      switch(args.mode){
        case 'clean':
        case 'login':
        case 'sign-in':
          this.toggleLoginForm();
          break;
        default:
          this.toggleSignUp();
          break;
      }

      if (args.redirect) {
        this.redirectAfterLogin = args.redirect;
      }
		});

		let urlParams = new URLSearchParams(window.location.search);

    // move redirection into memory -- not only from the url..
    if (urlParams.redirect){
      this.redirectAfterLogin = urlParams.redirect;
    }

		// Let's check if the we're in the app. This is tested via ?mode=app on the domain.
		if (urlParams) {
			if (urlParams.get('showSignIn') == 'true') {
				this.toggleLoginForm();
			}
		}

		this.EventBus.$on('DOM-keydown-fire', (event) => {
			if(this.showLoginForm) {
				if(event.keyCode == 27) {
					this.toggleLoginForm();
				}
			}
		});


		window.addEventListener("message", this.receiveMessage, false);

		setTimeout(() => {
			if(!window.Intercom) {
				return;
			}
			window.Intercom('onHide', () => {
				this.showingSupport = false;
			});

			window.Intercom('onUnreadCountChange', (unreadCount) => {
				this.intercomMessages = unreadCount;
			});

		}, 700);

    this.EventBus.$on('menu:toggle', this.toggleMenu);
	},
	computed: {
		documentURL() {
			return document.domain;
		},
		loggedIn() {
			return this.$store.getters.isLoggedIn;
		},
		account() {
			return this.$store.getters.getAccount;
    },
		isAdmin() {
			return this.$store.getters.isAlpha;
    },
    hasThreads() {
      return this.$store.getters.hasThreads;
    },
		storyTitle(){
			let getStory = this.$store.getters.getStory(this.$route.params.story);
			if(getStory && getStory.links[0]) {
				return getStory.links[0].name;
			} else {
				return false;
			}
		},
		storySlug() {
			if(this.$route.params.story == '-') {
				return false;
			} else {
				return this.$route.params.story;
			}
		},
		isArticle() {
			return this.$route.params.article || false;
		},
		hasMini() {
			return this.$store.getters.hasMini;
		},
		isAlpha() {
			return this.$store.getters.isAlpha;
		},
		isBeta() {
			return this.$store.getters.isBeta;
		},
		isPro() {
			return this.$store.getters.isPro;
    },
    isProHeart() {
      if (this.isPro) {
        return '💚';
      }
      return '';
    },
		detectMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.$store.getters.hasApp) {
				return true
			}
			return false;
		},
		detectAppOS() {
			if (/Android|webOS/i.test(navigator.userAgent)) {
				return 'android'
			} else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				return 'apple'
			}
			return false;
		},
	},
	methods: {
		// @Jim: Needs removing after testing
		didSelectTestMessage() {
			this.EventBus.$emit('showInboundMessage', {
        code: 'BRX001',
        callback: () => {
          // console.log("Choice was: ", code)
        }
      });
		},
    homeClick() {
      if (this.$route.path == '/'){
        // console.log("Reloading...");
        API.getHomeV3('', true); // forced, hard reload (ignore store age & force API cal)
      } else {
        // console.log("Navigating...");
        API.getHomeV3('', false); // hit at an unforced, soft reload (store will reload stale data)
        this.$router.push('/');
      }
    },
		determineTag() {
			const alpha = this.isAlpha;
			const beta = this.isBeta;

			if (alpha) { return 'alpha' }
			if (beta) { return 'beta' }
			return '';
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
		},
		becomePro() {
			this.EventBus.$emit("didSelectSubscribe", {
				didSelect: 'true' 
			});
		},
		getSupport() {
			// if(!window.Intercom) {
			// 	alert('Please send us an email at support@onesub.io');
			// 	return;
			// }
			// window.Intercom('show');
			// this.showingSupport = true;


			let payload = {
				articlePublisher: 'OneSub',
				articleURL: 'https://nourished.news/contact'
			}

			// --------------------------------
			// iOS Callback
			// --------------------------------
			try {
				window.webkit.messageHandlers.webviewCallback.postMessage(payload);
			} catch(err) { 
				console.error(err)
			}

			// --------------------------------
			// Android Callback
			// --------------------------------
			try {
				if (typeof Android !== 'undefined') {
					// eslint-disable-next-line
					Android["storyCallback"](JSON.stringify(payload));
				}
			} catch(err) { 
				console.error(err)
			}

			// --------------------------------
			// Web Callback
			// --------------------------------
			window.open(payload.url, '_blank').focus();
		},
		receiveMessage(message) {
			if(message.data.oneSubEvent) {
				if(message.data.oneSubEvent == 'toggleNavigation') {
					this.showMenu = !this.showMenu;
				}
			}
		},
		triggerReviewCallback() {
			this.EventBus.$emit("appCallback", {
				type: 'reviewCallback',
			});
		},
		hideMenu() {
			this.showMenu = false;
		},
		toggleFocus(element) {
			this.focusElement = element;
		},
		toggleSignUp() {
			this.toggleLoginForm();
			this.toggleSignUpForm();
		},
		toggleLoginForm() {
			this.showLoginForm = !this.showLoginForm;
		},
		submitForm() {
			this.showError = false;
			this.showUserError = false;
			this.showLoader = true;

			// Detect OS and Browser/App
			let platform = this.detectAppOS
			if (!platform) {  platform = (this.detectMobile) ? "mobile" : "desktop"; }

			const isApp = this.detectAppOS
			let scope;
			if (isApp) { scope = 'app'; } else { scope = 'browser'; }

			this.signInDetails.platform = platform
			this.signInDetails.scope = scope

			API.post(`/account/login`, this.signInDetails).then((response) => {
				this.showLoader = false;
				if(response.status.toString().substring(0,1) == 2) {
					this.$store.commit("updateAccount", response.data);

					this.showLoginForm = false;
					this.hideMenu();

					if (this.redirectAfterLogin) {
						this.$router.push(this.redirectAfterLogin);
						// console.log("Redirected post login");
						this.EventBus.$emit('api:account');
					} else {
						if (this.$route.path.substring(0,6) == '/login') {
							this.$router.push('/')
						}
						// else stay where we are..
					}

          // clear the redirection rules
          this.redirectAfterLogin = false;

          // Just send the token - let the app validate it.
					this.EventBus.$emit("appCallback", {
						type: 'authCallback',
						data: response.data.token
					});
					
				}
			}).catch((error) => {
				this.showLoader = false;
				if (error.response.status === 400) {
					this.showUserError = true;
				} else {
					this.showError = true;
				}
			});
		},
		toggleSignUpForm() {
			this.$store.commit("updateShowModal", 'skip');
		},
		forgotPassword() {
			if(this.hasMini) {
				document.location = `https://${this.documentURL}/r/x/tools/pwd.php`
			} else {
				let width 	= 500;
				let height 	= 500;
				let left 	= ((window.innerWidth / 2) - (width / 2));
				let top  	= ((window.innerHeight / 2) - (height / 2));

				let pop = window.open(
					`https://${this.documentURL}/r/x/tools/pwd.php`,
					"Password reminder",
					'scrollbars=yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left,
					0
				);

				// Puts focus on the newWindow
				if (window.focus) {
					pop.focus();
				}
			}
		},
    // For debugging device logout calls..
    deviceClear() {
      // Clear the store
      this.$store.commit('unsetAccount');
      // Clear the device
      this.EventBus.$emit("appCallback", {type: 'deauthCallback',data: 'logout'});
    },
    goThreadShare() {
      this.EventBus.$emit('thread:share', {
        on: true,
      });
    },
	},
	watch: {
		'$route': {
			handler: function() {
				this.showMenu = false;
			}
		}
	}
};

</script>

<style lang="scss" scoped>
	@import '~styles/_variables';
	@import '~styles/__2020_colors';

  .dial-container {
    padding-left: 0.5em;
    margin-top: -4px;
  }

  header .nav-wrapper {
    // all navigation, tags etc in Circular
    font-family: $ff-tag;
	font-weight: bold;
  }

	h1 {
		text-align: center;
		font-size: unset;
		line-height: unset;
		a {
			display: inline-block;
		}
	}

	.brand {
		display: flex;
	}

	.menu-wrapper {
		position: fixed;
		background: $white-secondary;
		top: 53px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.menu-wrapper.has-app {
		top: 0;
	}

	.hamburger-activate-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: -1rem;
	}

	.hamburger-activate {
		-webkit-appearance: none;
		border: none;
		background: none;
		padding: 0;
		cursor: pointer;
		outline: none;
		width: 53px;
		height: 53px;
		display: flex;
		align-items: center;
		justify-content: center;
		.hamburger-inner {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			.intercom-notification {
				position: absolute;
				width: 8px;
				height: 8px;
				background: $highlight-primary;
				border-radius: 50%;
				margin-left: 3px;
				margin-top: -2px;
				animation-name: bounce;
				animation-timing-function: ease;
				animation-duration: 0.5s;
				animation-iteration-count: 2;
			}
			@keyframes bounce {
				0%   { transform: translateY(0); }
				50%  { transform: translateY(-3px); }
				100% { transform: translateY(0); }
			}
			
		}
	}

	header > div {
    transition: background-color 0.5s ease;
		background: $base-primary;
		padding: 0 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 53px;
		position: fixed;
		width: 100%;
		top: 0;
		z-index: $iZIndexHeader;
		> * {
			flex: 1;
		}
	}

	.showing-support > div {
		z-index: $iZIndexHeader;
	}

	.main-nav li a,
	.faux-link {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1em 0;
		font-size: 12px;
		// font-weight: bold;
		color: $base_primary;
		letter-spacing: -0.14px;
	}

	.is-disabled {
		pointer-events: none;
		.feature-tag {
			pointer-events: all;
		}
		.svg-wrapper {
			opacity: 0.1;
		}
		span:not(.is-button) {
			color: rgb(171, 172, 173);
		}
	}

	.has-app .mobile {
		// height: 0;
		display: flex;
	}

	.mobile .main-nav {
		&.is-first {
			margin-top: 1em;
		}
		.is-search {
			padding-top: 1em;
		}
		li {
			&:not(.no-border) {
				border-bottom: 1px solid rgba(22,25,28,0.10);
				box-shadow: 0px 1px 0px 0px #fff;
			}
			a,
			> span {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1em 0;
				font-size: 12px;
				// font-weight: bold;
				color: $base_primary;
				letter-spacing: -0.14px;
			}
		}

		.icon {
			background: $white-primary;
			border: 1px solid rgb(231, 232, 232);
			border-radius: 2px;
			width: 24px;
			height: 24px;
			display: block;
			margin-right: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.router-link-exact-active {
			.icon {
				background: $highlight-primary;
				border-color: $highlight-primary;
			}
		}

		.feature-tag {
			padding: 4px 20px 3px 5px !important;
			background-position: right 5px center;
			font-size: 10px;
		}

	}

	.header-gravatar {
		display: inline-block;
	}

	.is-button {
		margin: 0;
	}

	.sign-up {
		display: flex;
		align-items: center;
		a {
			display: flex;
			align-items: center;
			.my-account-link {
				margin-right: 10px;
			}
		}
	}


	.sign-up img,
	.account-details img {
		border-radius: 50%;
		width: 48px;
		height: 48px;
		display: block;
	}

	.sign-up img {
		height: 30px;
		width: 30px;
		margin: 0;
	}


	.account-details {
		display: flex;
		align-items: center;
		div {
			h2 {
				font-size: 16px;
				color: rgb(22, 25, 28);
				letter-spacing: -0.23px;
				line-height: 20px;
				margin: 0;
				// font-weight: 700;
			}
			span {
				margin-top: -2px;
				display: block;
				font-size: 12px;
				color: rgb(22, 25, 28);
				letter-spacing: 0;
				line-height: 16px;
			}
		}
		.account-personal {
			margin-left: 1em;
		}
	}

	// Desktop modifications
	.desktop {
		height: 65px;
		h1 {
			text-align: left;
			margin: 0;
		}
		.last-sign {
			display: flex;
			justify-content: flex-end;
			.is-button + .is-button {
				margin-left: 1em;
			}
			a {
				transition: all 0.2s ease-in-out;
				height: 65px;
				color: $white-primary;
				font-size: 12px;
				// font-weight: bold;
				letter-spacing: -0.14px;
				line-height: 14px;
				border-top: 3px solid transparent;
				border-bottom: 3px solid transparent;
      }
      
      .threads-button {
        display: flex;
        background: $blue-tone-4;
        margin-right: 1em;

        span {
          color: white;
          padding-left: .5em;
        }
      }
		}
		.main-nav {
			flex: 1;
			align-items: center;
			justify-content: center;
			
			li {
				a,
				.faux-link {
					transition: all 0.2s ease-in-out;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1em 0;
					font-size: 12px;
					// font-weight: bold;
					color: $white-primary;
					letter-spacing: -0.14px;
					height: 65px;
					margin: 0 0.875em;
					border-top: 3px solid transparent;
					border-bottom: 3px solid transparent;
					&:hover,
					&.router-link-exact-active {
						border-top-color: $highlight-primary;
					}
				}
			}
		}
	}

	@media (max-width: 700px) {
		.mobile {
			display: flex;
		}
		.desktop {
			display: none;
		}
	}

	@media (min-width: 700px) {
		.desktop {
			display: flex;
		}
		.mobile {
			display: none;
		}
	}

</style>


<style lang="scss">

	body {
		overflow-x: hidden;
	}

	// header:not(.has-app) + div {
	// 	margin-top: 53px;
	// 	@media(min-width:700px) {
	// 		margin-top: 65px;
	// 	}
	// }

	.loader-wrapper {
		margin: 2rem 0 1rem 0;
	}

</style>
